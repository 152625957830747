import { SiteQuery } from '@graphql/generated';
import { useHaveUser } from '@hooks/use-have-user';
import { useAppContext } from '@lib/context/appContext';
import t from '@lib/locales';
import { Locales } from '@model/common';
import { useState } from 'react';
import config from '../../../data/config.json';
import Login from './Login';
import RecoverPassword from './RecoverPassword';
import Register from './Register';
const Close = '/images/icons/times.svg';

type Props = {
  locale: Locales;
  data: SiteQuery;
};

export const Authentication: React.FC<Props> = ({ locale, data }) => {
  const { privacy, subscribe, profiling = null } = data;
  const { projectName } = config;
  const { state, dispatch, client } = useAppContext();
  const { authModal: isActive, baseUrl } = state;

  const [isLogin, setLogin] = useState(true);
  const [isPwdRecover, setForgorgotPwd] = useState(false);
  const [user, setUser] = useState<string>();

  useHaveUser(setUser, projectName);

  const logout = async () => {
    try {
      await client.logout();
      handleClose();
      window?.location?.reload();
    } catch (error) {
      console.error('logout error', error);
    }
  };
  const handleClose = () => {
    setLogin(true);
    setForgorgotPwd(false);
    dispatch({ type: 'TOGGLE_AUTH_MODAL', data: false });
  };

  const onSuccess = (email?: string) => {
    if (!!email) {
      setUser(email);
    }
    dispatch({ type: 'TOGGLE_FEEDBACK_MODAL_USER', data: client });

    if (window?.location?.href?.indexOf('checkout') > -1) {
      return window.location.reload(); //reload
    }
    handleClose();
  };
  // we cannot change the role auth-dialog because of the styles of rare spirits
  return (
    <div role="auth-dialog" aria-label="Authentication">
      <div className={`dialog ${isActive ? 'is-active' : ''}`}>
        <div className="dialog__frame">
          <div className="dialog__header">
            <div className="dialog__close" onClick={handleClose}>
              <img className="dialog__close__icon" src={Close} alt="close" />
            </div>
          </div>

          <div className="dialog__body">
            {user && (
              <div className="dialog__body__content">
                <span className="dialog__body__content--title">{`${t(locale, 'logged_as')}`} </span>
                <span className="dialog__body__content--button">
                  <button className="button--primary" type="button" onClick={() => logout()}>
                    {`${t(locale, 'logout')}`}
                  </button>
                </span>
              </div>
            )}

            {!user && (
              <>
                <div>
                  {isLogin && !isPwdRecover && (
                    <Login client={client} locale={locale} onSuccess={onSuccess}>
                      <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                        <a
                          href="#"
                          className="button--small-primary-link"
                          onClick={() => setForgorgotPwd(true)}
                        >
                          {`${t(locale, 'forgot_password')}`}
                        </a>
                      </div>
                    </Login>
                  )}
                  {isLogin && isPwdRecover && (
                    <>
                      <RecoverPassword baseUrl={baseUrl} client={client} locale={locale}>
                        <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                          <a
                            href="#"
                            className="button--small-primary-link"
                            onClick={() => setForgorgotPwd(false)}
                          >
                            {t(locale, 'login_button')}
                          </a>
                        </div>
                      </RecoverPassword>
                    </>
                  )}
                </div>
                <div>
                  {!isLogin && (
                    <Register
                      client={client}
                      locale={locale}
                      privacy={privacy}
                      subscribe={subscribe}
                      profiling={profiling}
                      onSuccess={handleClose}
                    />
                  )}
                </div>
              </>
            )}
          </div>

          <div className="dialog__footer">
            {!user && !isPwdRecover && (
              <div>
                <button
                  className="button--small-primary-link"
                  type="button"
                  onClick={() => setLogin(!isLogin)}
                >
                  {isLogin ? t(locale, 'register_button') : t(locale, 'login_button')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
