type Props = {
  fillLogo?: string;
  className?: string;
};

export const CloseIcon: React.FC<Props> = ({ fillLogo = '#000', className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className={className}>
      <g fill={fillLogo} fillRule="evenodd">
        <rect
          width="25"
          height="2"
          x="-2.954"
          y="8.686"
          rx="1"
          transform="rotate(45 9.546 9.686)"
        ></rect>
        <rect
          width="25"
          height="2"
          x="-2.954"
          y="8.686"
          rx="1"
          transform="rotate(-46 9.546 9.686)"
        ></rect>
      </g>
    </svg>
  );
};
