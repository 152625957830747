import { Locales } from '@model/common';
import Script from 'next/script';

import { InjectSnippetRecord } from '@graphql/generated';
import config from '../../../data/config.json';

type Props = {
  locale: Locales;
  data: InjectSnippetRecord;
};

const Snippet: React.FC<Props> = ({ locale, data }) => {
  const body = data._allBodyLocales.find((bodyLocale) => bodyLocale.locale === locale);
  if (!!body?.value) {
    switch (data.snippetType) {
      case 'css-head-link':
        return <link href={body.value} rel="stylesheet" />;
      case 'css-block':
        return <style dangerouslySetInnerHTML={{ __html: body.value }}></style>;
      case 'js-head-link':
        return (
          <Script
            id={data.id}
            src={body.value}
            {...(data.scriptParams as any)}
            strategy={
              data.scriptLoadStrategy as
                | 'afterInteractive'
                | 'lazyOnload'
                | 'beforeInteractive'
                | 'worker'
            }
            onError={(e) => {
              console.error('Script failed to load', e);
            }}
          />
        );
      case 'html-block':
        return <div dangerouslySetInnerHTML={{ __html: body.value }} />;
      case 'js-block':
        return (
          <Script
            id={data.friendlyName}
            {...(data.scriptParams as any)}
            strategy={
              data.scriptLoadStrategy as
                | 'afterInteractive'
                | 'lazyOnload'
                | 'beforeInteractive'
                | 'worker'
            }
            dangerouslySetInnerHTML={{ __html: body.value }}
            onError={(e) => {
              console.error('Script failed to load', e);
            }}
          />
        );
    }
  }
  return null;
};

export const Snippets: React.FC<{ locale: Locales }> = ({ locale }) => {
  return (
    <>
      {config.configuration?.snippets?.map((s) => (
        <Snippet key={s.id} data={s as InjectSnippetRecord} locale={locale} />
      ))}
    </>
  );
};
