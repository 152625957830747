import { zodResolver } from '@hookform/resolvers/zod';
import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import t, { tt } from '@lib/locales';
import { trackLogin } from '@lib/utils/gtmUtils';
import { Locales } from '@model/common';
import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

type Props = {
  client: AppCommerceLayerClient;
  locale: Locales;
  onSuccess: any;
  children: ReactNode;
};

const Login: React.FC<Props> = ({ client, locale, onSuccess, children }) => {
  const [ui, setUi] = useState({ errorMessage: null, loading: false });

  const schema = z
    .object({
      email: z
        .string()
        .min(1, t(locale, 'field_required'))
        .email(t(locale, 'enter_valid_email'))
        .toLowerCase()
        .trim(),
      password: z.string().min(6, tt(locale, 'password_min_chars', { min: '6' })),
    })
    .required({
      email: true,
      password: true,
    });

  type Schema = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: Schema) => {
    setUi({ errorMessage: null, loading: true });
    let errorMessage = null;
    try {
      //@ts-ignore
      await client.login(data);
      trackLogin();
      onSuccess(data.email);
    } catch (error) {
      errorMessage = t(locale, 'invalid_credentials');
    } finally {
      setUi({ errorMessage, loading: false });
    }
  };

  return (
    <div className="auth">
      <h4 className="auth__title">{t(locale, 'login')}</h4>
      {ui.errorMessage && <div className="form__error">{ui.errorMessage}</div>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__field">
          <input
            type="email"
            required
            placeholder={t(locale, 'email')}
            {...register('email')}
            className="form__input"
            autoComplete="email"
          />
          {errors.email && <span className="form__error">{errors.email.message.toString()}</span>}
        </div>
        <div className="form__field">
          <input
            type="password"
            required
            placeholder={t(locale, 'password')}
            {...register('password')}
            className="form__input"
          />
          {errors.password && (
            <span className="form__error">{errors.password.message.toString()}</span>
          )}
        </div>
        <div className="form__field">
          {!ui.loading && (
            <button className="button--primary" type="submit">
              {t(locale, 'login')}
            </button>
          )}
          {ui.loading && <div> {t(locale, 'loading')}</div>}
        </div>
      </form>
      {children}
    </div>
  );
};
export default Login;
