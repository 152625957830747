import { getUserSession } from '@utils/session-storage';
import { useEffect } from 'react';

export const useHaveUser = (setUser: (user: string) => void, projectName: string) => {
  useEffect(() => {
    const user = getUserSession();
    if (user) {
      setUser(user.email);
    }
  });
};
