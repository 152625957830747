import { Tag } from '@graphql/generated';
import { getLocales } from '@lib/utils/pathUtils';
import { AltsType, Locales } from '@model/common';
import Head from 'next/head';
import { renderMetaTags } from 'react-datocms';

const HOST = process.env.HOST || '';

type Props = {
  title: string;
  tags: Tag[];
  alts?: AltsType;
  baseUrl: string;
};

/**
 * Remove this when switched from pages router to app router
 */
export const SEO: React.FC<Props> = ({ title = '', tags = [], alts, baseUrl = '' }) => {
  const titleMeta = tags.find((t) => t.tag === 'title');
  if (!titleMeta && title) {
    tags.unshift({ tag: 'title', content: title, attributes: undefined });
  }

  const langs = getLocales();

  return (
    <Head>
      {renderMetaTags(tags)}
      {alts &&
        Object.keys(alts).map((l) => {
          if (langs.includes(l)) {
            const { slug, path } = alts[l as Locales];
            const endPath = path ? `/${path}` : '';
            const url = `${HOST}${baseUrl}/${l}${endPath}`;
            return (
              <link
                key={url}
                href={url}
                title={slug}
                type="text/html"
                hrefLang={l}
                rel="alternate"
              />
            );
          }
        })}
    </Head>
  );
};
