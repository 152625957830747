import { useAppContext } from '@lib/context/appContext';
import t from '@lib/locales';
import { getCountryName } from '@lib/utils/clUtils';
import { Locales } from '@model/common';

type Props = {
  locale: Locales;
};

const CountrySelector: React.FC<Props> = ({ locale }) => {
  const { state, dispatch } = useAppContext();
  function showMarketSelector() {
    dispatch({ type: 'TOGGLE_COUNTRY_MODAL', data: true });
  }
  if (!state.hasMultipleCountries) return null;
  return (
    <div className="change_market" style={{ display: 'block' }}>
      <span className="change_market--title">{t(locale, 'change_market')}</span>
      <a
        title={t(locale, 'change_market')}
        // we cannot change the role auth-dialog because of the styles of masi
        role="change-market"
        className="nav__menu__link"
        onClick={showMarketSelector}
      >
        {getCountryName(locale, state.countryCode)}
      </a>
    </div>
  );
};
export default CountrySelector;
