'use client';

import { FeedbackUser } from '@components/account/dialogs/FeedbackUser';
import { FeedbackDialogQuantity } from '@components/dialogs/FeedbackDialogQuantity';
import { PaymentErrorDialog } from '@components/dialogs/PaymentErrorDialog';
import { SiteQuery, Tag } from '@graphql/generated';
import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import { useAuth } from '@lib/b2b/authContext';
import { useAppContext } from '@lib/context/appContext';
import t from '@lib/locales';
import { getMarketFromUrl, getPathFromMarket } from '@lib/utils/clUtils';
import { isHome } from '@lib/utils/pathUtils';
import { AltsType, Locales, PageParams } from '@model/common';
import { COUNTRY_CODE_KEY, MARKET_ID_KEY } from '@shared/constants';
import { isB2BEnabled } from '@utils/config';
import { localStorageGetItem, localStorageSetItem } from '@utils/local-storage';
import { deleteCartIdSession, deleteUserSession } from '@utils/session-storage';
import { useRouter } from 'next/navigation';
import { ReactNode, useEffect, useState } from 'react';
import { Authentication } from '..';
import { FeedbackDialog } from '../dialogs/FeedbackDialog';
import { MarketSelector } from '../dialogs/MarketSelector';
import { Footer } from './Footer';
import { Header } from './Header';
import { SEO } from './SEO';
import { Snippets } from './Snippets';

type Props = {
  params: PageParams;
  data: SiteQuery;
  tags?: Tag[];
  cssBodyClass: string;
  children?: ReactNode;
  title?: string;
  pageType?: string;
  showHeader?: boolean;
  alts?: AltsType;
};

export const Layout: React.FC<Props> = ({
  children,
  title,
  data,
  pageType,
  showHeader = true,
  params,
  alts,
  tags = [],
  cssBodyClass,
}) => {
  const { user, loading, logOut } = useAuth();
  const { state, dispatch, client } = useAppContext() as {
    state: any;
    dispatch: any;
    client: AppCommerceLayerClient;
  };
  const { hasMultipleCountries } = state;
  const router = useRouter();
  const { projectName } = state;
  const { currency, market, lang: locale } = params;
  const baseUrl = `/${market}/${currency}`;
  const defaultTitle = title || data.site?.globalSeo?.fallbackSeo?.title;
  const [calledPush, setCalledPush] = useState(false);

  useEffect(() => {
    if (isB2BEnabled && !loading && !user) {
      if (calledPush) {
        return; // no need to call router.push() again
      }
      router.push('/');
      setCalledPush(true);
    }
  }, [user, loading]);

  const logOutAndReload = async () => {
    await logOut();
    try {
      router.replace('/');
    } catch (error) {}
  };

  function redirect(marketId: number, locale: Locales) {
    if (!marketId) {
      return;
    }
    const currentUrl = window.location.href;
    const redirectUrl = getPathFromMarket(marketId, locale);
    if (currentUrl.indexOf(redirectUrl || '') < 0) {
      const search = window.location.search;
      const url = `${redirectUrl}${search}`;
      router.push(url);
    }
  }

  //on market selected
  function handleMarketChange(marketId: number, countryCode: string, locale: Locales) {
    const data = { marketId, countryCode };
    if (client) {
      client.cleanup();
    } else {
      deleteCartIdSession();
      deleteUserSession();
    }
    localStorageSetItem(`${projectName}${MARKET_ID_KEY}`, marketId.toString());
    localStorageSetItem(`${projectName}${COUNTRY_CODE_KEY}`, countryCode);
    dispatch({ type: 'INIT_MARKET', data });
    // dispatch({ type: 'SET_CART_ID', data: null });

    redirect(marketId, locale);
  }

  useEffect(() => {
    const currentUrl = window.location.href;
    //check if have multiple market setted
    if (typeof hasMultipleCountries !== 'undefined') {
      const savedMarketId = parseInt(localStorageGetItem(`${projectName}${MARKET_ID_KEY}`));
      if (!savedMarketId) {
        if (hasMultipleCountries) {
          //dispatch modal only if path is home
          if (isHome(currentUrl)) {
            // DIALOG
            dispatch({ type: 'TOGGLE_COUNTRY_MODAL', data: true });
          } else {
            const urlMarket = getMarketFromUrl(currentUrl);
            if (urlMarket) {
              // CHANGE TO MARKET IN CURRENT URL
              handleMarketChange(urlMarket.marketNumber, urlMarket.countryCode, locale);
            }
          }
        }
      } else {
        const marketBaseUrl = getPathFromMarket(savedMarketId, locale);
        const isDifferent = currentUrl.indexOf(marketBaseUrl || '') < 0;

        if (isDifferent) {
          if (!isHome(currentUrl)) {
            const urlMarket = getMarketFromUrl(currentUrl);
            handleMarketChange(urlMarket!.marketNumber, urlMarket!.countryCode, locale);
          } else {
            redirect(savedMarketId, locale);
          }
        }
      }
    }
  }, [hasMultipleCountries]);

  //SAVE CURRENT LOCALE AND BASE URL TO CONTEXT
  useEffect(() => {
    if (locale && baseUrl) {
      dispatch({ type: 'SET_LOCALE', data: locale });
      dispatch({ type: 'SET_BASE_URL', data: baseUrl });
    }
  }, [locale, baseUrl]);

  // ADD CSS CUSTOM CLASSES
  useEffect(() => {
    const body = document.body;
    if (body && cssBodyClass) {
      const classes = cssBodyClass.split(' ').filter(Boolean); // Filtra le stringhe vuote
      classes.forEach((cls) => {
        body.classList.add(cls);
      });
    }

    return () => {
      const body = document.body;
      if (body && cssBodyClass) {
        const classes = cssBodyClass.split(' ').filter(Boolean); // Filtra le stringhe vuote
        classes.forEach((cls) => {
          body.classList.remove(cls);
        });
      }
    };
  }, [cssBodyClass]); // Esegui l'effetto solo quando `cssBodyClass` cambia

  useEffect(() => {
    document.documentElement.lang = locale;
  }, [locale]);

  const logo =
    data.header?.logo?.responsiveImage ||
    data.header?.logo?.url ||
    data.config?.logo?.responsiveImage ||
    data.config?.logo?.url ||
    '';
  return (
    <>
      <div className="canvas" lang={locale}>
        {isB2BEnabled && user && (
          <div style={{ padding: 4, textAlign: 'right', background: '#f4f4f4' }}>
            <span style={{ marginRight: 10 }}>{user.email}</span>
            <button
              className="button button--primary"
              onClick={async () => await logOutAndReload()}
            >
              {t(locale, 'logout')}
            </button>
          </div>
        )}
        <div className="canvas__main">
          <Header
            baseUrl={baseUrl}
            locale={locale}
            logo={logo}
            menu={data.menu}
            alts={alts}
            showHeader={showHeader}
          />
          <main className="wrap">{children}</main>
          <Footer lang={locale} baseUrl={baseUrl} data={data.footer} fallbackLogo={logo} />
        </div>
      </div>
      <MarketSelector locale={locale} onMarketChange={handleMarketChange} />
      <Authentication locale={locale} data={data} />
      <FeedbackDialog locale={locale} baseUrl={baseUrl} />
      <FeedbackUser locale={locale} baseUrl={baseUrl} />
      <FeedbackDialogQuantity locale={locale} baseUrl={baseUrl} />
      <PaymentErrorDialog locale={locale} baseUrl={baseUrl} />

      <SEO
        title={defaultTitle}
        tags={[...tags, ...data.site.favicon]}
        alts={alts}
        baseUrl={baseUrl}
      />
      <Snippets locale={locale} />
    </>
  );
};
