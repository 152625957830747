'use client';

import { InternalLinkModelPageFieldFragment, Maybe } from '@graphql/generated';
import { useAppContext } from '@lib/context/appContext';
import { subPaths } from '@lib/utils/pathUtils';
import { Locales } from '@model/common';
import { ReactNode, useEffect, useState } from 'react';

type Props = {
  internalLink: Maybe<InternalLinkModelPageFieldFragment>;
  className: string;
  title?: Maybe<string> | undefined;
  showTitle?: boolean;
  children?: ReactNode;
  target?: string;
  rel?: string;
};

export const InternalLink: React.FC<Props> = ({
  internalLink,
  showTitle = true,
  title = null,
  children,
  className,
}) => {
  const { state } = useAppContext();
  const { baseUrl } = state;
  const locale: Locales = state.locale;
  const [prefix, setPrefix] = useState<string>();

  useEffect(() => {
    if (locale && baseUrl) {
      setPrefix(`${baseUrl}/${locale}`);
    }
  }, [locale, baseUrl]);

  if (!prefix || !internalLink) {
    return <div>{children}</div>;
  }

  let path = '';
  switch (internalLink.__typename) {
    case 'ProductRecord':
      path = `/${subPaths.products[locale]}/${internalLink.slug}`;
      title = title || internalLink.name || internalLink.slug;
      break;
    case 'PageRecord':
      path = `/${internalLink.slug}`;
      title = title || internalLink.title || internalLink.slug;
      break;
    case 'CategoryRecord':
      path = `/${subPaths.categories[locale]}/${internalLink.slug}`;
      title = title || internalLink.name || internalLink.slug;
      break;
  }
  const url = `${prefix}${path}`;
  return (
    <a href={url} className={className} key={internalLink.id}>
      {showTitle && <>{title}</>}
      {children}
    </a>
  );
};
